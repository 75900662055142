import image1 from "../images/Submenu/Submenu-1.jpg"
import image2 from "../images/Submenu/Submenu-2.jpg"
import image3 from "../images/Submenu/Submenu-3.jpg"

export const menuOptions = [
  {
    item: "La Pedrera",
    link:"la-pedrera",
    option: [
      {
        link: "/",
        text: "La façana",
        images: [
          {
            id: 1,
            image: image1,
            text: "la facana"
          },
          {
            id: 2,
            image: image3,
            text: "la facana2"
          },
        ]
      },
      {
        link: "/",
        text: "El terrat",
        images: [
          {
            id: 3,
            image: image3,
            text: "el terrat"
          },
          {
            id: 4,
            image: image2,
            text: "el terrat 2"
          },
        ]
      },
      {
        link: "/",
        text: "Els cels rasos",
      },
      {
        link: "/",
        text: "La porta: forja i disseny",
      },
      {
        link: "/",
        text: "Barcelona 1900",
      },
      {
        link: "/",
        text: "Gaudí i disseny",
      },
      {
        link: "/",
        text: "Exposicions ",
      },
    ],
    images: [
      image1,
      image2,
      image3
    ]
  },
  {
    item: "Joieria",
  },
  {
    item: "Moda i accessoris",
  },
  {
    item: "Llar",
  },
  {
    item: "Oficina",
  },
  {
    item: "Infantil",
  },
  {
    item: "Llibres",
    link:"/libres"
  },
  {
    item: "Regals",
  },
]

export const menuData = [
  {
    title: "La Pedrera",
    options: [
      {
        title: "La façana",
        link: "/",
      },
      {
        title: "El terrat",
        link: "/",
      },
      {
        title: "Els cels rasos",
        link: "/",
      },
      {
        title: "La porta: forja i disseny",
        link: "/",
      },
      {
        title: "Barcelona 1900: el pis",
        link: "/",
      },
      {
        title: "Gaudi i disseny",
        link: "/",
      },
      {
        title: "Exposicions",
        link: "/",
      },
    ],
  },
  {
    title: "Joieria",
    options: [
      {
        title: "Tota la joieria",
        link: "/",
      },
      {
        title: "Arracades",
        link: "/",
      },
      {
        title: "Collarets",
        link: "/",
      },
      {
        title: "Anells",
        link: "/",
      },
      {
        title: "La porta: forja i disseny",
        link: "/",
      },
      {
        title: "Polseres i agulles de pit",
        link: "/",
      },
    ],
  },
  {
    title: "Moda i accessoris",
    options: [
      {
        title: "Tota la joieria",
        link: "/",
      },
      {
        title: "Arracades",
        link: "/",
      },
      {
        title: "Collarets",
        link: "/",
      },
      {
        title: "Anells",
        link: "/",
      },
      {
        title: "La porta: forja i disseny",
        link: "/",
      },
      {
        title: "Polseres i agulles de pit",
        link: "/",
      },
    ],
  },
  {
    title: "LLar",
    options: [
      {
        title: "Tota la joieria",
        link: "/",
      },
      {
        title: "Arracades",
        link: "/",
      },
      {
        title: "Collarets",
        link: "/",
      },
      {
        title: "Anells",
        link: "/",
      },
      {
        title: "La porta: forja i disseny",
        link: "/",
      },
      {
        title: "Polseres i agulles de pit",
        link: "/",
      },
    ],
  },
  {
    title: "Oficina",
    options: [
      {
        title: "Tota la joieria",
        link: "/",
      },
      {
        title: "Arracades",
        link: "/",
      },
      {
        title: "Collarets",
        link: "/",
      },
      {
        title: "Anells",
        link: "/",
      },
      {
        title: "La porta: forja i disseny",
        link: "/",
      },
      {
        title: "Polseres i agulles de pit",
        link: "/",
      },
    ],
  },
  {
    title: "Infantil",
    options: [
      {
        title: "Tota la joieria",
        link: "/",
      },
      {
        title: "Arracades",
        link: "/",
      },
      {
        title: "Collarets",
        link: "/",
      },
      {
        title: "Anells",
        link: "/",
      },
      {
        title: "La porta: forja i disseny",
        link: "/",
      },
      {
        title: "Polseres i agulles de pit",
        link: "/",
      },
    ],
  },
  {
    title: "Llibres",
    options: [
      {
        title: "Veure tots els llibres",
        link: "/",
      },
      {
        title: "La Pedrera",
        link: "/la-pedrera",
      },
      {
        title: "Gaudí",
        link: "/",
      },
      {
        title: "1900",
        link: "/libres",
      },
      {
        title: "Barcelona",
        link: "/",
      },
      {
        title: "Disseny, moda i arts aplicades",
        link: "/",
      },
      {
        title: "Infantil",
        link: "/",
      },
      {
        title: "Cultura",
        link: "/",
      },
      {
        title: "Exposicions",
        link: "/",
      },
    ],
  },
  {
    title: "Regals",
    options: [
      {
        title: "Veure tots els regals",
        link: "/",
      },
      {
        title: "For Architecture Lovers",
        link: "/",
      },
      {
        title: "For Design Lovers",
        link: "/",
      },
      {
        title: "For Nature lovers",
        link: "/",
      },
      {
        title: "For Conscience",
        link: "/",
      },
      {
        title: "Best Sellers",
        link: "/",
      },
      {
        title: "Regals Premium",
        link: "/",
      },
      {
        title: "Especial per empreses",
        link: "/",
      },
    ],
  },
]