import React from 'react'
import { Fragment } from 'react';
import SvgIconData from "./SvgCurveData";

export default function SvgCurve(props) {
    const { color, icon, className } = props;
    return (
        <Fragment>
            <div className={className}>
                <SvgIconData color={color} icon={icon} />
            </div>
        </Fragment>
    );
}
