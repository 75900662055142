import React, { useState } from "react";
import { Link } from "gatsby";
import logo from "../../images/Header/logo.png";
import logoMobile from "../../images/Header/logo-mobile.png";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
} from "reactstrap";
import Submenu from "../SubMenu";
import { menuOptions, menuData } from "../../data/menu-items"
import { Fragment } from "react";

export default function Header() {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);
	const toggleMenu = () => setOpenMenu(!openMenu);

	const renderMenuOptions = () => {
		return (
			menuData &&
			menuData.map((item) => {
				return (
					<UncontrolledDropdown nav inNavbar>
						<DropdownToggle
							className="HeaderMobile"
							nav
						>
							{item.title}
							<i className="i-chevron-icon" />
						</DropdownToggle>
						<DropdownMenu
							right
							className="Header-mobile-links"
						>
                            {renderMenuOption(item.options)}
						</DropdownMenu>
					</UncontrolledDropdown>
				);
			})
		);
	};

	const renderMenuOption = (options) => {
		return (
			options &&
			options.map((item) => {
				return (
					<DropdownItem className="Header-mobile-item">
						<Link className="Header-mobile-link" to={item.link}>{item.title}</Link>
					</DropdownItem>
				);
			})
		);
	};

	const renderOptions = () => {
		return (
			menuOptions &&
			menuOptions.map((item, index) => {
			return (
			  <li className="Header-item" key={`navbar-${index}`}>
				{renderItems(item)}
			  </li>
			)
		  })
		)
	}

	const renderItems = item => {
		if (!item?.option) {
		  return (
			<Link to={item.link} className="Header-link">
			  {item.item}
			</Link>
		  )
		}
	
		return (
		  <Fragment>
			<Link to={item.link} className="Header-link">
			  {item.item}
			</Link>
			<Submenu list={item.option} title={item.item} images={item.images} />
		  </Fragment>
		)
	  }

	return (
		<header className="Header">
			<div className="Header-container">
				<div className="Header-mobile-logo">
					<Link to="/" className="Header-mobile-title">Official store</Link>
					<Link to="/">
						<img
							className="Header-mobile-img"
							src={logoMobile}
							alt="Logo"
						/>
					</Link>
				</div>
				<div className="Header-mobile-actions">
					<button className="Header-mobile-btn" onClick={toggleMenu}>
						<i className="i-hamburger-icon" />
					</button>
					<div className="Header-mobile-icons">
						<Link to="/acceder" className="Header-icon">
							<i className="i-user" />
						</Link>
						<Link to="/cesta/cart" className="Header-icon">
							<div className="Header-mobile-badge">2</div>
							<i className="i-cart" />
						</Link>
					</div>
				</div>
				<div className={openMenu ? "Header-mobile-options" : "d-none"}>
					{renderMenuOptions()}
				</div>
				<div className="Header-logo d-none d-lg-block">
					<Link to="/">
						<img
							className="Header-logo"
							src={logo}
							alt="Logo La Pedrera"
						/>
					</Link>
				</div>
				<ul className="Header-items d-none d-lg-flex">
					{renderOptions()}
					<div className="Header-icons">
						<div className="Header-icon">
							<i className="i-search" />
						</div>
						<Link to="/acceder" className="Header-icon">
							<i className="i-user" />
						</Link>
						<Link to="/cesta/cart" className="Header-icon">
							<i className="i-cart" />
							<div className="Header-badge">2</div>
						</Link>
						
						<Dropdown
							className="Header-dropdown"
							isOpen={dropdownOpen}
							toggle={toggle}
						>
							<DropdownToggle caret>CAT</DropdownToggle>
							<DropdownMenu>
								<DropdownItem>CAT</DropdownItem>
								<DropdownItem>ES</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
				</ul>
			</div>
		</header>
	);
}
