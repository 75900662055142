import React from "react";
import PropTypes from "prop-types";

const SvgIconData = (props) => {
    const { icon } = props;

    const curveSvg1440 = (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="58.735" viewBox="0 0 100% 58.735">
            <path id="Union_10" data-name="Union 10" d="M19290-2962.21l40-3.273c40-3.151,120-9.881,200-3.243,80,6.516,160,26.094,240,32.61,80,6.638,160-.092,240-6.516s160-13.154,240-16.3c80-3.273,160-3.273,240-1.652,80,1.744,160,4.8,240,8.168s160,6.424,240,3.273c80-3.273,160-13.062,200-17.957l40-4.9v58.735H19290Z" transform="translate(-19290 2972)" fill="#869194" />
        </svg>
    );

    const curveSvgMobile = (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="17" viewBox="0 0 100% 17">
            <path id="Union_12" data-name="Union 12" d="M0,242.221v-224H375v224Zm363.188-264H-4V-36.633l7.812-.994c7.813-.956,23.438-3,39.063-.984,15.625,1.977,31.25,7.918,46.875,9.9,15.625,2.015,31.25-.028,46.876-1.977s31.25-3.991,46.875-4.947c15.625-.994,31.25-.994,46.875-.5,15.625.529,31.25,1.458,46.876,2.479s31.25,1.949,46.875.993c15.625-.993,31.25-3.964,39.063-5.45L371-39.6v17.824Z" transform="translate(4 39.604)" fill="#869194" />
        </svg>
    );

    const curveSvgHero = (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="69.773" viewBox="0 0 100% 69.773">
            <path id="Union_6" data-name="Union 6" d="M21124-2811h-106.666c-106.668,0-320,0-533.334,10.345-213.334,10.248-426.666,31.083-640,32.294s-426.666-16.717-533.334-25.85L19204-2803.247v62.02h1920Z" transform="translate(-19204 2811)" fill="#fff" />
        </svg>
    );

    const curveSvgHeroMobile = (
        <svg xmlns="http://www.w3.org/2000/svg" width="374.998" height="18.17" viewBox="0 0 374.998 18.17">
            <path id="Union_17" data-name="Union 17" d="M19564-2574h-20.832c-20.834,0-62.5,0-104.166,2.694-41.668,2.669-83.334,8.094-125,8.41s-83.334-4.353-104.168-6.732l-20.832-2.353v16.151h375Z" transform="translate(-19189 2574)" fill="#fff" />
        </svg>

    );

    const curveSvgHeroTablet = (
        <svg xmlns="http://www.w3.org/2000/svg" width="768" height="69.77" viewBox="0 0 768 69.77">
            <path id="Union_6" data-name="Union 6" d="M19972-2811h-42.668c-42.666,0-128,0-213.332,10.345-85.334,10.248-170.668,31.083-256,32.294s-170.666-16.717-213.334-25.85L19204-2803.25v62.02h768Z" transform="translate(-19204 2811)" fill="#fff" />
        </svg>
    );

    const bag = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-bag" class="svg-inline--fa fa-shopping-bag fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M352 160v-32C352 57.42 294.579 0 224 0 153.42 0 96 57.42 96 128v32H0v272c0 44.183 35.817 80 80 80h288c44.183 0 80-35.817 80-80V160h-96zm-192-32c0-35.29 28.71-64 64-64s64 28.71 64 64v32H160v-32zm160 120c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm-192 0c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24z"></path>
        </svg>
    );

    const heart = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
        </svg>
    );

    const cardId = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="address-card" class="svg-inline--fa fa-address-card fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-352 96c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm112 236.8c0 10.6-10 19.2-22.4 19.2H86.4C74 384 64 375.4 64 364.8v-19.2c0-31.8 30.1-57.6 67.2-57.6h5c12.3 5.1 25.7 8 39.8 8s27.6-2.9 39.8-8h5c37.1 0 67.2 25.8 67.2 57.6v19.2zM512 312c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-64c0 4.4-3.6 8-8 8H360c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z"></path></svg>
    );

    const addresses = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
    );

    const keyIcon = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="key" class="svg-inline--fa fa-key fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z"></path></svg>
    );

    const infoIcon = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" class="svg-inline--fa fa-info-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg>
    );

    const logout = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" class="svg-inline--fa fa-sign-out-alt fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
    );

    const chevronDown = (
        <svg class="icon" viewBox="6 0 12 24"><polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12"></polygon></svg>
    );
    const editIcon = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" class="svg-inline--fa fa-edit fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg>
    );
    const trashIcon = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" class="svg-inline--fa fa-trash-alt fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
    );
    const arrowBack = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" class="svg-inline--fa fa-arrow-left fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path></svg>
    );
    const cart = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="shopping-cart" class="svg-inline--fa fa-shopping-cart fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"></path></svg>
    );
    const userCart = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa fa-user fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg>
    );
    const box = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="box-open" class="svg-inline--fa fa-box-open fa-w-20 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"></path></svg>
    );
    const gift = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gift" class="svg-inline--fa fa-gift fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z"></path></svg>
    );
    const check = (
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
    );
    const heroCurveSmall = (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="18.172" viewBox="0 0 428 18.172">
            <path id="Union_17" data-name="Union 17" d="M19608.225-3380.84H19204v-16.151l23.771,2.354c23.777,2.378,71.332,7.046,118.893,6.732s95.107-5.739,142.666-8.407c47.559-2.7,95.107-2.7,118.889-2.7H19632v18.171Z" transform="translate(-19204.002 3399.012)" fill="#fff"/>
        </svg>
    );
    const spotlightLarge = (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="94.901" viewBox="0 0 1920 94.901">
            <path id="Union_2" data-name="Union 2" d="M1786.333,882.919c-106.667,0-320,0-533.333-14.071-213.333-13.938-426.667-42.278-640-43.925s-426.667,22.738-533.333,35.16L-27,872.374V788.018H1893v94.9Z" transform="translate(1893 882.919) rotate(180)" fill="#f1efec"/>
        </svg>
    );
    const spotlightExtraSmall = (
        <svg xmlns="http://www.w3.org/2000/svg" width="375" height="37.005" viewBox="0 0 375 37.005">
            <path id="Union_2" data-name="Union 2" d="M354.168,863.83c-20.834,0-62.5,0-104.167-5.486-41.667-5.435-83.334-16.485-125-17.128s-83.334,8.866-104.167,13.711L0,859.719V826.825H375V863.83Z" transform="translate(375 863.83) rotate(180)" fill="#f1efec"/>
        </svg>
    );
    const spotlightSmall = (
        <svg xmlns="http://www.w3.org/2000/svg" width="415" height="37.005" viewBox="0 0 415 37.005">
            <path id="Union_2" data-name="Union 2" d="M391.946,863.83c-23.056,0-69.167,0-115.278-5.486-46.111-5.435-92.223-16.485-138.333-17.128s-92.223,8.866-115.278,13.711L0,859.719V826.825H415V863.83Z" transform="translate(415 863.83) rotate(180)" fill="#f1efec"/>
        </svg>
    );

    const data = [
        {
            icon: "curve1440",
            svg: curveSvg1440,
        },
        {
            icon: "curveSvgMobile",
            svg: curveSvgMobile,
        },
        {
            icon: "curveSvgHero",
            svg: curveSvgHero,
        },
        {
            icon: "curveSvgHeroMobile",
            svg: curveSvgHeroMobile,
        },
        {
            icon: "curveSvgHeroTablet",
            svg: curveSvgHeroTablet,
        },
        {
            icon: "bag",
            svg: bag,
        },
        {
            icon: "heart",
            svg: heart,
        },
        {
            icon: "cardId",
            svg: cardId,
        },
        {
            icon: "addresses",
            svg: addresses,
        },
        {
            icon: "keyIcon",
            svg: keyIcon,
        },
        {
            icon: "infoIcon",
            svg: infoIcon,
        },
        {
            icon: "logout",
            svg: logout,
        },
        {
            icon: "chevronDown",
            svg: chevronDown,
        },
        {
            icon: "editIcon",
            svg: editIcon,
        },
        {
            icon: "trashIcon",
            svg: trashIcon,
        },
        {
            icon: "arrowBack",
            svg: arrowBack,
        },
        {
            icon: "cart",
            svg: cart,
        },
        {
            icon: "userCart",
            svg: userCart,
        },
        {
            icon: "box",
            svg: box,
        },
        {
            icon: "gift",
            svg: gift,
        },
        {
            icon: "check",
            svg: check,
        },
        {
            icon: "heroCurveSmall",
            svg: heroCurveSmall,
        },
        {
            icon: "spotlightLarge",
            svg: spotlightLarge,
        },
        {
            icon: "spotlightExtraSmall",
            svg: spotlightExtraSmall,
        },
        {
            icon: "spotlightSmall",
            svg: spotlightSmall,
        },
    ];

    const svg = data.find((d) => {
        return d.icon === icon;
    });

    return svg.svg;
};

SvgIconData.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
};

SvgIconData.defaultProps = {
    icon: "star",
    color: "white",
};

export default SvgIconData;
