import React from "react";
import { Link } from "gatsby";
import logo from "../../images/Footer/footer-logo.png";
import {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
} from "reactstrap";
import Curve from "../SvgCurve"

export default function Footer() {
	return (
		<footer className="Footer">
			<Curve className="Footer-curve-large" icon="curve1440" />
			<Curve className="Footer-curve-short" icon="curveSvgMobile" />
			<div className="Footer-container">
				<div className="Footer-content">
					<img className="Footer-logo" src={logo} alt="" />
					<div className="Footer-icons">
						<i className="i-footer" />
						<i className="i-footer" />
						<i className="i-footer" />
					</div>
					<div className="Footer-links">
						<div className="Footer-options">
							<p className="Footer-title">Ajuda</p>
							<ul className="Footer-list">
								<li className="Footer-item">
									<Link className="Footer-link">
										Servei d’atenció al client
									</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">
										Comandes internacionals
									</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">
										Enviament
									</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">
										Devolucions
									</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">
										El meu compte
									</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">
										Targetes regal
									</Link>
								</li>
							</ul>
						</div>
						<div className="Footer-options">
							<p className="Footer-title">La Botiga</p>
							<ul className="Footer-list">
								<li className="Footer-item">
									<Link className="Footer-link">
										Pedrera Store
									</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">Agenda</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">
										Ultimes notícies
									</Link>
								</li>
							</ul>
						</div>
						<div className="Footer-options">
							<p className="Footer-title">Contacte</p>
							<ul className="Footer-list">
								<li className="Footer-item">
									<Link className="Footer-link">
										Carrer de Provença, 261
										<br />
										08008 Barcelona
									</Link>
								</li>
								<li className="Footer-item">
									<Link className="Footer-link">
										pedrera@laie.es
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="Footer-mobile-options">
					<UncontrolledDropdown nav inNavbar>
						<DropdownToggle className="Footer-mobile-dropdown" nav>
							Ajuda
							<i className="i-footer-chevron-icon" />
						</DropdownToggle>
						<DropdownMenu right className="Footer-mobile-links">
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
									Servei d’atenció al client
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Comandes internacionals
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Enviament
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Devolucions
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    El meu compte
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Targetes regal
								</Link>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
					<UncontrolledDropdown nav inNavbar>
						<DropdownToggle className="Footer-mobile-dropdown" nav>
                            La Botiga
							<i className="i-footer-chevron-icon" />
						</DropdownToggle>
						<DropdownMenu right className="Footer-mobile-links">
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Pedrera Store
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Agenda
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Ultimes notícies
								</Link>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
					<UncontrolledDropdown nav inNavbar>
						<DropdownToggle className="Footer-mobile-dropdown" nav>
                            Contacte
							<i className="i-footer-chevron-icon" />
						</DropdownToggle>
						<DropdownMenu right className="Footer-mobile-links">
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    Carrer de Provença, 261 08008 Barcelona
								</Link>
							</DropdownItem>
							<DropdownItem className="Footer-mobile-item">
								<Link className="Footer-mobile-link" to="/">
                                    pedrera@laie.es
								</Link>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
                <p className="Footer-copyright">2020 Todos los derechos reservados</p>
			</div>
		</footer>
	);
}
