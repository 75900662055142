import React, { useState } from 'react'
import { Link } from 'gatsby';

export default function Submenu(props) {
    const { title, list, images } = props;

    const [withHover, setWithHover] = useState(false);
    const [listCurrent, setListCurrent] = useState([]);

    const elementWithHover = (listImg) => () => {
      setWithHover(true)
      if(!listImg?.length) return 
      setListCurrent(listImg)
    }
    const elementWithoutHover = () => setWithHover(false)

    const renderOptions = () => {
        return (
          list &&
          list.map((item) => {
            return (
              <button onMouseEnter={elementWithHover(item.images)} onMouseOut={elementWithoutHover} className="Submenu-item" onBlur={()=>{}}>
                <Link className="Submenu-link" to={item.link}>
                  {item.text}
                </Link>
              </button>
            )
          })
        )
      }

      const renderImage = () => {
          return images&&images.map((item)=> {
            return <img className="Submenu-image" src={item} alt="text" />
          })
      }

      

      const renderImageHover = () => {
        return listCurrent&&listCurrent.map((item, index)=> {
          return <img className="Submenu-image" id={index} src={item.image} alt={item.text} /> 
        }) 
      }

      const renderContent = () => {
          if(!withHover){
              return <div className="Submenu-images">{renderImage()}</div> 
          }
          return <div className="Submenu-images">{renderImageHover()}</div> 
      }

    return (
      <ul className="Submenu">
        <div className="Submenu-container">
          <div className="Submenu-wrapper">
            <span className="Submenu-title">{title}</span>
            {renderOptions()}
          </div>
           {renderContent()}
        </div>
      </ul>
    )
}
